import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import JobSix1 from '../images/JobSix1.jpg';
import JobSix2 from '../images/JobSix2.jpg';
import JobSix3 from '../images/JobSix3.jpg';
import JobSix4 from '../images/JobSix4.jpg';
import JobSix5 from '../images/JobSix5.jpg';
import JobSix6 from '../images/JobSix6.jpg';
import JobSix7 from '../images/JobSix7.jpg';
import JobSix8 from '../images/JobSix8.jpg';
import JobSix9 from '../images/JobSix9.jpg';
import JobSix10 from '../images/JobSix10.jpg';
import JobSix11 from '../images/JobSix11.jpg';
import JobSix12 from '../images/JobSix12.jpg';
import JobSix13 from '../images/JobSix13.jpg';
import JobSix14 from '../images/JobSix14.jpg';
import JobSix15 from '../images/JobSix15.jpg';
// import { ImageListItemBar } from '@material-ui/core';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const oakJob = [
  JobSix1,
  JobSix2,
  JobSix3,
  JobSix4,
  JobSix5,
  JobSix6,
  JobSix7,
  JobSix8,
  JobSix9,
  JobSix10,
  JobSix11,
  JobSix12,
  JobSix13,
  JobSix14,
  JobSix15
];

const oakJobObject = oakJob.map((image, i) => ({
  id: i,
  image: image
}));

function Gallery(props) {  
    const { value, index, gallery, ...other } = props;
  
      return (
            <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
          >
            {value === index && (
              <div>
                  <div className="services-overview">
                  <Typography variant="h3" component="h3">
                      Gallery
                  </Typography>
  
                  <Typography className="larger-font" style={{marginBottom: '1em', marginTop: '1em'}} variant="subtitle1" component="div">
                      Here are images of some of the work carried out by Mardy's Tree Care.
                  </Typography>
              </div>
              <Box>
                {/* {gallery.map((job) => (
                  <ImageList key={job.id} variant="masonry" cols={cols} gap={8}>
                    {job.images.map((image, i) => (
                      <ImageListItem key={i}>
                        <img
                          src={`${image}?w=248&fit=crop&auto=format`}
                          srcSet={`${image}?w=248&fit=crop&auto=format&dpr=2 2x`}
                          alt="Yo"
                          loading="lazy"
                        />
                      </ImageListItem>
                    ))}
                  </ImageList>
                ))} */}
                <ImageList variant="masonry" cols={2} gap={8}>
                  {gallery.map((item) => (
                    <Box>
                      <ImageListItem key={item.id}>
                        <img
                          src={`${item.image}?w=248&fit=crop&auto=format`}
                          srcSet={`${item.image}?w=248&fit=crop&auto=format&dpr=2 2x`}
                          alt=""
                          loading="lazy"
                        />
                      </ImageListItem>
                    </Box>
                  ))}
                </ImageList>
                <ImageList variant="masonry" cols={2} gap={8}>
                  {oakJobObject.map((item) => (
                    <ImageListItem key={item.id}>
                      <img
                        src={`${item.image}?w=248&fit=crop&auto=format`}
                        srcSet={`${item.image}?w=248&fit=crop&auto=format&dpr=2 2x`}
                        alt=""
                        loading="lazy"
                      />
                      {/* <ImageListItemBar title="Oak Dismantle"/> */}
                    </ImageListItem>
                  ))}
                </ImageList>
              </Box>
              </div>
            )}
            
          </div> 
      )
    
  }
  
  export default Gallery