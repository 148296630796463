import Emergency from '../images/EmergencyTreeWorks.jpg';
import Dismantle from '../images/JobFour3.jpg';
import Reduction from '../images/CrownReduction.jpg';
import Thinning from '../images/CrownThinning.jpg';
import Raising from '../images/CrownRaising.jpg';
import Pollarding from '../images/Pollarding.jpg';
import Deadwood from '../images/DeadWooding.JPG';
import FruitTree from '../images/FruitTreePruning.jpg';
import Ivy from '../images/IvyRemovalBanding.jpg';
import HedgeTrim from '../images/HedgeCutting.jpg';
import HedgeRemoval from '../images/HedgeCutting.jpg';
import Stump from '../images/StumpGrinding.jpg';
import EcoPlug from '../images/EcoPlug.jpg';
import Clearances from '../images/SiteClearance.jpg';
import Assessments from '../images/TreeAssessments.jpg';

export function ServicesList() {
    const servicesList = [
        {
            title: "Tree Felling & Dismantling",
            image: Dismantle,
            description: "Tree felling or dismantling is the entire removal of a tree. Your tree might be in a tight tricky location in close proximity to structures or buildings or have BT cables/electrical cables running through the canopy and therefore cannot be felled. In these cases, we can dismantle your tree in sections using rigging techniques consisting of ropes, pulleys and devices to lower branches and timber safely without causing damage to any obstacles or underground services."
        },

        {
            title: "Crown Reduction",
            image: Reduction,
            description: "A crown reduction is the reduction of height and/or spread of the entire tree crown. This pruning could be used if your tree has outgrown its environment, or to reduce mechanical stress on branches. You could prevent structural damage to a building or just want to allow more sunlight to a target area."
        },
        
        {
            title: "Crown Thinning",
            image: Thinning,
            description: "Does your tree have a busy dense Crown? Crown thinning is a pruning technique removing selective stems and branches to allow increased light penetration and air flow throughout the crown."
        },

        {
            title: "Crown Raising/lifting",
            image: Raising,
            description: "Crown raising/lifting is the removal of low branches from the crown. This form of pruning would be used to increase the clearance between ground level and the lower tree canopy. Benefits could be to achieve statutory height clearances over a public footpath or highway for vehicles or even to allow sunlight to a window or for smaller shrubs/plants to benefit and flourish."
        },

        {
            title: "Pollarding",
            image: Pollarding,
            description: "Pollarding is a pruning method used to keep a tree at a smaller height than what it would naturally grow to. It's the initial removal of the top of a tree at a prescibed height to encourage multistem branching from that point onwards. Once pollarded, this process should be done on a specified cycle to restrict the tree to that height."
        },
        
        {
            title: "Dead Wooding",
            image: Deadwood,
            description: "Dead wooding is the removal of dead, non living branches or stems due to natural aging or external influences. Removing dead wood could prevent damage or injury to someone or something in it's drop zone."
        },    
        
        {
            title: "Fruit Tree Pruning",
            image: FruitTree,
            description: "Fruit tree pruning is the cutting and removing of selected parts of a fruit tree, to encourage fruiting. Pruning is good to remove diseased wood or dying wood and stimulate the formation of flowers and fruit buds."
        }, 
        
        {
            title: "Ivy Removal & banding",
            image: Ivy,
            description: "Ivy banding is performed by cutting a ring of ivy around the base of the stem/trunk at ground level. This must be done carefully not causing any damage to the bark or tree, by doing this it will cut the circulation and allow the ivy on the tree to die off. In some severe cases we can climb and remove ivy from branches within the crown of the tree. Ivy can sometimes provide shelter for many forms of wildlife but it's control can be advisable."
        }, 

        {
            title: "Hedge Trimming & Shaping",
            image: HedgeTrim,
            description: "Here at Mardy's tree care, we take great pride in any hedge trimming job we take on and offer a fantastic service. We can also setup a maintenance plan to keep your hedges looking there best all year, every year."
        }, 
        
        {
            title: "Hedge Removals",
            image: HedgeRemoval,
            description: "As well as Hedge Trimming and shaping, we can also completely remove any unwanted hedges you may have."
        }, 

        {
            title: "Stump grinding",
            image: Stump,
            description: "Stump grinding is the final process of a tree removal. Stump grinders come in various sizes and most commonly incorporate a cutting wheel with fixed carbide teeth which grinds the stump into chippings that are then back filled into the hole. Stump grinding is a great option if you’re looking to re-plant or need removal for a building or landscaping project or even just to remove a potential trip hazard."
        }, 

        {
            title: "Eco plugs",
            image: EcoPlug,
            description: "Eco plugs are a great option if you’re not able to access a stump with a stump grinder. Eco plugs kill the tree stump when the capsules are hit into the pre-drilled holes in the xylem and phloem of the stump and prevents any re-growth. These plugs can be used in all weather conditions and will not cause any effects to neighbouring trees."
        }, 

        {
            title: "Site Clearances",
            image: Clearances,
            description: "Site clearances are usually carried out for new developments, or maybe you just want your garden completely cleared for a new project. This can involve tree felling, hedge removal, stump grinding and scrub clearance, with the aim to leave a blank canvas for whatever your new exciting project maybe."
        }, 
        
        {
            title: "Tree Assessments",
            image: Assessments,
            description: "Mardy's tree care can carry out tree assessments and advise of any recommended pruning or felling that maybe required if any defects are found. As well as ground-based assessments, we can also perform aerial climbing inspections."
        }, 
        
        {
            title: "Emergency Tree Works",
            image: Emergency,
            description: "Mardy's Tree Care are available for emergency call outs in the unfortunate event of any tree related disruption. Storm damage, wind-blown or fallen trees are just a few emergencies to name. Whatever the scenario, we have a fully-fledged qualified team of arborists to deal with the most challenging events."
        }  
    ];

    const servicesOject = servicesList.map((service, i) => ({
        id: i,
        title: service.title,
        image: service.image,
        description: service.description
    }));

    return servicesOject;
}