import { React, useState } from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { CardActionArea } from '@mui/material';
import { ImCheckboxChecked } from 'react-icons/im';
import { FaClipboardList } from 'react-icons/fa';
import { AiOutlineForm } from 'react-icons/ai';
import { BiPound } from 'react-icons/bi';
import Commercial from '../images/BeforePhoto5.jpg';
import Domestic from '../images/BeforePhoto2.jpg';
import Hero from '../images/MainLogo.png';
import { TestimonialCard } from "./testimonial-card";
import { Testimonials } from "../lists/testimonials";  

export function Home() {
  const [items] = useState(Testimonials);

    return (
        <div>
            <section className="hero">
                <div className="container">
                  <img src={Hero} alt="Alt Logo" />
                </div>
                <div className="container">
                  <div className="hero-content">
                    <List sx={{width: '90%'}} className="list-items">
                      <ListItem>
                          <ListItemIcon>
                            <ImCheckboxChecked size={32} color="#6FAD1B" />
                          </ListItemIcon>
                          <ListItemText primary="Fully Insured" />
                      </ListItem>
                      <ListItem>
                          <ListItemIcon>
                            <FaClipboardList size={32} color="#6FAD1B" />
                          </ListItemIcon>
                          <ListItemText primary="Free TPO and Conservation Applications" />
                      </ListItem>
                      <ListItem>
                          <ListItemIcon>
                            <AiOutlineForm size={32} color="#6FAD1B" />
                          </ListItemIcon>
                          <ListItemText primary="Free Quotations" />
                      </ListItem>
                      <ListItem>
                          <ListItemIcon>
                            <BiPound size={32} color="#6FAD1B" />
                          </ListItemIcon>
                          <ListItemText primary="Competitive Prices" />
                      </ListItem>
                    </List>
                  
                  <div className="about-center">
                    <Typography variant="body1">
                      Mardy's tree care are a small family run business with over 15 years of experience in tree care and arboriculture. We are based in Cadnam, New Forest and operate throughout Hampshire, Dorset and surrounding areas and consist of a friendly close-knit team of highly skilled professional arborists, who are all trained with the relevant up to date NPTC qualifications. There is no job too big or small at Mardy's tree care, and customer satisfaction is something we pride ourselves on, from start to finish we deliver our customers a professional top-quality service undertaking all aspects of tree services in accordance with British standards BS3998.
                    </Typography>
                  </div>
                  </div>
                </div>                
            </section>

            <section className="services-section"> 
              <div className="container"> 
                <div>
                  <h1 className="services-heading">
                    <Typography variant="h3" component="h3">
                      Services
                    </Typography>
                  </h1>
                  <Typography className="services-blurb" variant="body1">
                    Mardy's Tree Care offer a wide range of services. To view a list of the most common ones we offer, including a description of what the service entails, either click on one of the two cards below, or navigate via our menu at the top of the page.
                  </Typography>
                </div>
              </div>    
                <div className="container spaceout">   
                  <Card className="home-service-card">
                    <CardActionArea href="/services">
                      <CardMedia
                        sx={{width: '378px', height: '283.5px'}}
                        component="img"
                        image={Domestic}
                        alt="Domestic Services"
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h6" component="div">
                          Domestic Services
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          We offer a range of domestic services
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                  <Card className="home-service-card">
                    <CardActionArea href="/services">
                      <CardMedia
                        sx={{width: '378px', height: '283.5px'}}
                        component="img"
                        image={Commercial}
                        alt="Commercial Services"
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h6" component="div">
                          Commercial Services
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          We offer a range of commercial services
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </div>        
            </section>

            <section className="testimonials-section">
                <div className="container">
                  <div>
                  <h1>
                    <Typography variant="h3" component="h3">
                      Testimonials
                    </Typography>
                  </h1>
                  <div className="card-container">
                    {items.map(({ itemId, testimonial }) => (
                      <TestimonialCard key={itemId}
                        itemId={itemId} // NOTE: itemId is required for track items
                        quote={testimonial.quote}
                        image={testimonial.image}
                        name={testimonial.name}
                        area={testimonial.area}
                      />))}
                  </div>
                </div>
              </div>
            </section>
        </div>
    );
}

