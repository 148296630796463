import React from 'react';
import { Routes, Route } from "react-router-dom";
import { Home } from "./components/home";
import { Whoops404 } from './components/notFound';
import { ServicesList } from './lists/services-list';
import { PortfolioList } from './lists/portfolio-list';
import { GalleryList } from './lists/gallery-list';
import Services from './components/services';
import Contact from './components/contact';
import Portfolio from './components/portfolio';
import './App.scss';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />}/> 
      <Route path="/contact" element={<Contact />}/>
      <Route path="/services" element={<Services services={ServicesList()} />}/>
      <Route path="/portfolio" element={<Portfolio portfolio={PortfolioList()} gallery={GalleryList()} />}/>
      <Route path="*" element={<Whoops404 />}/>    
    </Routes>
  );
}

export default App;
