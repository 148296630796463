import React from 'react';
import Typography from '@mui/material/Typography';
import { ServiceCard } from './service-card';

function Services(props) {
    return (
        <section className="services-sect">
            <div className="main-services">
                <div className="container">
                    <div className="services-overview">
                        <Typography variant="h3" component="h3">
                            Offered Services
                        </Typography>
                        <Typography className="larger-font" style={{marginBottom: '1em', marginTop: '1em'}} variant="subtitle1" component="div">
                            Listed here is a range of the main services I offer. Click on each of the cards to get a description of what the service involves.
                        </Typography>
                    </div>
                    <div className="services-block">
                        {props.services.map((service) => (
                            <ServiceCard key={service.id} name={service.title} image={service.image} description={service.description} />
                        ))}
                    </div>
                </div>
            </div>
        </section> 
    )
  
}

export default Services;