import React, { useState } from 'react';
import ReactCardFlip from 'react-card-flip';

export function PortfolioCard({
    before,
    after
}) {
    const [isFlipped, setFlip] = useState(false);

    const handleClick = () => {
        setFlip(!isFlipped);
    }

    return (
        <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
            <div onClick={handleClick} className="portfolio-card">
                <img src={before} alt="Before" />
                <h3>Before</h3>
            </div>

            <div onClick={handleClick} className="portfolio-card">
                <img src={after} alt="After" />
                <h3>After</h3>
            </div>
        </ReactCardFlip>       
    );
}