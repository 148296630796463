import React, { useState } from 'react';
import { useForm  } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { RiMailSendFill } from 'react-icons/ri';
import { AiOutlineForm } from 'react-icons/ai';
import * as yup from "yup";
import { send } from "emailjs-com";
import Loader from '../components/loader';
import { useAlert } from 'react-alert';
import Typography from '@mui/material/Typography';

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const serviceId = 'service_5hiezk5';
const templateId = 'template_jtja4d7';
const userId = 'user_OVnsmBK5WqEf79b2PjkwT';

const schema = yup.object().shape({
    name: yup.string().required('Required'),
    phone: yup.string().matches(phoneRegExp, {message: 'Phone number is not valid', excludeEmptyString:true}).nullable(),
    service: yup.string().required('Required'),
    message: yup.string().required('Required'),
    email: yup.string().email('Invalid email format').required('Required')
  });

export function QuoteForm(props)  {
    const alert = useAlert();
    const [loading, setLoading] = useState(false); 
    const { register, handleSubmit, setValue, formState:{ errors } } = useForm({
        resolver: yupResolver(schema)
      });

    const onSubmit = data => {
        setLoading(true);

        const sendParams = {
            to_name: 'Daniel Mardy',
            from_name: data.name,
            service: data.service,
            message: data.message,
            email: data.email,
            phone: data.phone === '' ? 'no number provided' : data.phone
        }
        
        send(serviceId, templateId, sendParams,userId)
            .then(function(response) {
                console.log('Success', response.status, response.text);
                setValue("name", "");
                setValue("email", "");
                setValue("phone", "");
                setValue("message", "");
                setValue("service", "");
                setLoading(false);
                alert.show('Message Sent Successfully', {type: 'success'});                
            })
            .catch(function(error) {
                console.log('Failure', error.status, error.text);
                setLoading(false);
                alert.show('Message Failed', {type: 'error'});
            });
                  
        console.log(data);        
    } 

    return (
        <div>
            <Typography style={{marginBottom: '0.5em'}} variant="h3" component="h3">
                Request a Quote
                <AiOutlineForm color="var(--primary-color)"/>
            </Typography>
        
            <form onSubmit={handleSubmit(onSubmit)}>

                    <label htmlFor="name">Name</label>
                    <input className={errors.name?.message ? "input-error" : ""} {...register("name")} disabled={loading} />
                    <strong>{errors.name?.message}</strong>
        
                    <label htmlFor="email">Email</label>
                    <input className={errors.email?.message ? "input-error" : ""} {...register("email")} disabled={loading}/>
                    <strong>{errors.email?.message}</strong>

                    <label htmlFor="phone">Telephone number</label>
                    <input className={errors.phone?.message ? "input-error" : ""} {...register("phone")} disabled={loading}/>
                    <strong>{errors.phone?.message}</strong>
        
                    <label htmlFor="service">Service</label>
                    <select className={errors.service?.message ? "input-error" : ""} {...register("service")} disabled={loading}>
                        {props.services.map(( service ) => (
                            <option key={service.id} value={service.title}>{service.title}</option>
                        )) }
                        <option value="Other">Other</option>
                    </select>
        
                    <label htmlFor="message">Details of work required</label>
                    <textarea className={errors.message?.message ? "input-error" : ""} cols="30" rows="10" {...register("message")} disabled={loading}></textarea>
                    <strong>{errors.message?.message}</strong>
                
                <div className="submit">
                    <button type="submit" className="send-message-cta" value="Send Request" disabled={loading}> 
                        Send Request <RiMailSendFill size={20}/>
                    </button>
                    {
                        loading && 
                        <Loader />
                    }                                      
                </div>
                
            </form>

        </div>
    )
}

export default QuoteForm;