import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { PortfolioCard } from './portfolio-card';
import Gallery from './gallery';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function Portfolio(props) {  
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
    return (
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', backgroundColor: 'white' }}>
            <Tabs className="container" value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Before and After" {...a11yProps(0)} />
              <Tab label="Gallery" {...a11yProps(1)} /> 
            </Tabs>
          </Box>     
          <section className="services-sect">      
            <div className="container">
              <div className="main-services">
                <Before value={value} index={0} portfolio={props.portfolio} />
                <Gallery value={value} index={1} gallery={props.gallery} />
              </div>
            </div>  
          </section>          
         </Box>
    )
  
}

function Before(props) {  
  const { value, index, ...other } = props;
    return (
          <div
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
        >
          {value === index && (
            <div>
              <div className="services-overview">
              <Typography variant="h3" component="h3">
                  Before and After Shots
              </Typography>

              <Typography className="larger-font" style={{marginBottom: '1em', marginTop: '1em'}} variant="subtitle1" component="div">
                  Here you can find a subset of my portfolio with before and after shots. You can click on one of the cards to go back and forth between the before and after.
              </Typography>
              </div>
              <div className="portfolio-block">
                  {props.portfolio.map((port) => (
                      <PortfolioCard key={port.id} before={port.before} after={port.after} />
                  ))}
              </div>
            </div>
          )}
          
        </div> 
    )
  
}

export default Portfolio;