import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import Header from './components/header';
import Footer from './components/footer';
import reportWebVitals from './reportWebVitals';
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';
import { createTheme, ThemeProvider, responsiveFontSizes, } from '@mui/material/styles';

const options = {
  position: positions.BOTTOM_CENTER,
  timeout: 5000,
  offset: '30px',
  transition: transitions.SCALE
}

let theme = createTheme({
  palette: {
    primary: {
      main: '#6FAD1B',
    }
  }
});
theme = responsiveFontSizes(theme);

ReactDOM.render(
  <Router>
    <AlertProvider template={AlertTemplate} {...options}>
    <ThemeProvider theme={theme}>
      <Header />
      <App />
      <Footer />
    </ThemeProvider>
    </AlertProvider>
  </Router>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
