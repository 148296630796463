import React from "react";
import {useLocation} from "react-router-dom";

export function Whoops404() {
    let location = useLocation();
    return (
        <div className="not-found">
            <h1>Resource not found at {location.pathname}</h1>
        </div>
    );
}