import React from 'react';
import {useLocation} from "react-router-dom";
import NPTC from '../images/Lantra.png';

function Footer() {
  let location = useLocation();

    return (    
      <div className="footer">
        <section className="primary-footer">
          <div className="container">
            <div className="free-quote">
              <h2>GET A FREE QUOTE</h2>
              <a href="/contact">Message Us</a>
            </div>
            <div className="links">
              <h3>Links</h3>
              <ul>
                <li className={location.pathname === "/" ? "current" : ""}><a href="/">Home</a></li>
                <li className={location.pathname.includes('services') ? "current" : ""}><a href="/services">Services</a></li>
                <li className={location.pathname.includes('portfolio') ? "current" : ""}><a href="/portfolio">Portfolio</a></li>
                <li className={location.pathname.includes('contact') ? "current" : ""}><a href="/contact">Contact</a></li>
              </ul>
            </div>
            <div className="accreditations">
              <img src={NPTC} alt="Accreditations" />
            </div>
          </div>
        </section>
        <div className="container">
            <p>Web Design &amp; Development: </p>
            <p>Vincent Howard</p>
            <p>vince_howard@hotmail.co.uk</p>
        </div>
      </div>    
    )
  
}

export default Footer;