import React from 'react';
import logo from '../images/3DLogo.png';
import menu from '../images/menu.svg';
import exit from '../images/exit.svg';
import {useLocation} from "react-router-dom";

function Header() {
    let location = useLocation();
    const openMenu = () => {
        let nav = document.querySelector('nav');
        nav.classList.add('menu-btn');
    };

    const ExitMenu = () => {
        let nav = document.querySelector('nav');
        nav.classList.remove('menu-btn');
    };

    return (    
      <div className="navbar">
          <div className="container">
              <img className="logo" src={logo} alt="Logo" />
              <img id="mobile-cta" className="mobile-menu" onClick={openMenu} src={menu} alt="Navigation Menu" />
              <nav>
                  <img id="mobile-exit" className="mobile-menu-exit" onClick={ExitMenu} src={exit} alt="Navigation Menu Exit" />

                  <ul className="primary-nav">
                      <li className={location.pathname === "/" ? "current" : ""}><a href="/">Home</a></li>
                      <li className={location.pathname.includes('services') ? "current" : ""}><a href="/services">Services</a></li>
                      <li className={location.pathname.includes('portfolio') ? "current" : ""}><a href="/portfolio">Portfolio</a></li>
                  </ul>

                  <ul className="secondary-nav">
                      <li className="contact-cta"><a className={location.pathname.includes('contact') ? "contact-selected" : ""} href="/contact">Contact</a></li>
                  </ul>
              </nav>            
          </div>
      </div>    
    )
  
}

export default Header;