import Loader from "react-loader-spinner";
import React from 'react';

export default class App extends React.Component {
  //other logic
  render() {
    return (
      <Loader
        type="ThreeDots"
        color="var(--primary-color)"
        height={50}
        width={50}
        className="loader"
      />
    );
  }
}