import BeforeOne from '../images/BeforePhoto1.jpg';
import BeforeTwo from '../images/BeforePhoto2.jpg';
import BeforeThree from '../images/BeforePhoto3.jpg';
import BeforeFour from '../images/BeforePhoto4.jpg';
import BeforeFive from '../images/BeforePhoto5.jpg';
import BeforeSix from '../images/BeforePhoto6.jpg';
import BeforeSeven from '../images/BeforePhoto7.jpg';
import BeforeEight from '../images/BeforePhoto8.jpg';
import BeforeNine from '../images/BeforePhoto9.jpg';
import BeforeTen from '../images/BeforePhoto10.jpg';
import BeforeEleven from '../images/BeforePhoto11.jpg';
import BeforeTwelve from '../images/BeforePhoto12.jpg';
import BeforeThirteen from '../images/BeforePhoto13.JPG';
import AfterOne from '../images/AfterPhoto1.jpg';
import AfterTwo from '../images/AfterPhoto2.jpg';
import AfterThree from '../images/AfterPhoto3.jpg';
import AfterFour from '../images/AfterPhoto4.jpg';
import AfterFive from '../images/AfterPhoto5.jpg';
import AfterSix from '../images/AfterPhoto6.jpg';
import AfterSeven from '../images/AfterPhoto7.jpg';
import AfterEight from '../images/AfterPhoto8.jpg';
import AfterNine from '../images/AfterPhoto9.jpg';
import AfterTen from '../images/AfterPhoto10.jpg';
import AfterEleven from '../images/AfterPhoto11.jpg';
import AfterTwelve from '../images/AfterPhoto12.jpg';
import AfterThirteen from '../images/AfterPhoto13.JPG';

export function PortfolioList() {
    const portfolioList = [
        {
            before: BeforeOne,
            after: AfterOne
        },
        {
            before: BeforeTwo,
            after: AfterTwo
        },
        {
            before: BeforeThree,
            after: AfterThree
        },            
        {
            before: BeforeSix,
            after: AfterSix
        },
        {
            before: BeforeSeven,
            after: AfterSeven
        },        
        {
            before: BeforeNine,
            after: AfterNine
        },        
        {
            before: BeforeThirteen,
            after: AfterThirteen
        },
        {
            before: BeforeTwelve,
            after: AfterTwelve
        },
        {
            before: BeforeFour,
            after: AfterFour
        },    
        {
            before: BeforeTen,
            after: AfterTen
        },
        {
            before: BeforeEight,
            after: AfterEight
        },
        {
            before: BeforeFive,
            after: AfterFive
        },
        {
            before: BeforeEleven,
            after: AfterEleven
        }
    ];
    
    const portfolioOject = portfolioList.map((portfolio, i) => ({
        id: i,
        before: portfolio.before,
        after: portfolio.after
    }));

    return portfolioOject;
}