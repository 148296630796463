import React from 'react';
import QuoteForm from '../classes/quoteForm';
import { ServicesList } from '../lists/services-list';
import { BiMapPin } from 'react-icons/bi';
import { BsExclamationCircle } from 'react-icons/bs';
import Typography from '@mui/material/Typography';

function Contact() {
    return (
        <section className="contact">
            <div className="container">

                <div className="quote-overview">
                    <Typography variant="h3" component="h3">
                        Contact
                    </Typography>
                    <Typography className="larger-font" style={{marginBottom: '1em', marginTop: '1em'}} variant="subtitle1" component="div">
                        Below you can fill out a form that I will receive via email, which is my preferred method of contact due to the nature of my work. Alternatively, you can contact me via my mobile on <span style={{fontWeight: "bold"}}>07465998714</span> but please bear in mind that I may be working when you call so please leave a message. With any form of contact I will aim to get back to you within 48 hours.
                    </Typography>
                </div>

                <div className="quote-form-container">
                    <div className="left-col-quote">
                        <QuoteForm services={ServicesList()}/>
                    </div>

                    <div className="right-col-quote">
                        <Typography style={{marginBottom: '0.3em'}} variant="h3" component="h3">
                            Areas Covered <BiMapPin color="var(--primary-color)"/>
                        </Typography>                        
                        <iframe title="Areas Covered" src="https://www.google.com/maps/d/embed?mid=1daUoLoWQuzyO0VEitOxyAnNsvxsNUu7M" width="600" height="450"></iframe>
                        <Typography variant="subtitle1">
                            *Areas covered but not limited to
                        </Typography>
                    </div>
                </div>

                <div className="emergency-container">
                    <Typography variant="h3" component="h3">
                        24/7 Emergency Tree Work <BsExclamationCircle />
                    </Typography>
                    <Typography className="larger-font" style={{marginBottom: '1em', marginTop: '1em'}} variant="subtitle1" component="div">
                        I am available 24/7 for emergency tree work. Please use the mobile number highlighted in bold at the top of the page. If unanswered leave a message stating it's for emergency work and I will get back to you ASAP.
                    </Typography>
                </div>

            </div>
        </section>
    )
}

export default Contact;