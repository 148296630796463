import JobOne1 from '../images/JobOne1.JPG';
import JobOne2 from '../images/JobOne2.JPG';
import JobOne3 from '../images/JobOne3.JPG';
import JobOne4 from '../images/JobOne4.jpg';
import JobTwo1 from '../images/JobTwo1.jpg';
import JobTwo2 from '../images/JobTwo2.jpg';
import JobTwo3 from '../images/BeforePhoto2.jpg';
import JobTwo4 from '../images/AfterPhoto2.jpg';
import JobThree1 from '../images/JobThree1.JPG';
import JobThree2 from '../images/JobThree2.JPG';
import JobThree3 from '../images/JobThree3.JPG';
import JobThree4 from '../images/JobThree4.JPG';
import JobThree5 from '../images/JobThree5.JPG';
import JobFour2 from '../images/JobFour2.jpg';
import JobFour3 from '../images/JobFour3.jpg';
import JobFive1 from '../images/JobFive1.jpg';
import JobFive2 from '../images/JobFive2.jpg';
import JobFive3 from '../images/JobFive3.jpg';
import JobFive4 from '../images/JobFive4.jpg';
// import JobSix1 from '../images/JobSix1.jpg';
// import JobSix2 from '../images/JobSix2.jpg';
// import JobSix3 from '../images/JobSix3.jpg';
// import JobSix4 from '../images/JobSix4.jpg';
// import JobSix5 from '../images/JobSix5.jpg';
// import JobSix6 from '../images/JobSix6.jpg';
// import JobSix7 from '../images/JobSix7.jpg';
// import JobSix8 from '../images/JobSix8.jpg';
// import JobSix9 from '../images/JobSix9.jpg';
// import JobSix10 from '../images/JobSix10.jpg';
// import JobSix11 from '../images/JobSix11.jpg';
// import JobSix12 from '../images/JobSix12.jpg';
// import JobSix13 from '../images/JobSix13.jpg';
// import JobSix14 from '../images/JobSix14.jpg';
// import JobSix15 from '../images/JobSix15.jpg';
import JobSeven1 from '../images/services1.jpg';
import JobSeven2 from '../images/services2.jpg';
import JobSeven3 from '../images/services6.jpg';
import JobSeven4 from '../images/services7.jpg';
import JobSeven5 from '../images/services8.jpg';
import JobSeven6 from '../images/services9.jpg';
import JobSeven7 from '../images/services10.jpg';
import JobSeven8 from '../images/services11.jpg';
import JobSeven9 from '../images/services12.jpg';
import JobSeven10 from '../images/MardyCrane.jpg';

export function GalleryList() {
    // const galleryList = [
    //     {
    //         images: [JobOne1, JobOne2, JobOne3, JobOne4]
    //     },
    //     {
    //         images: [JobTwo1, JobTwo2, JobTwo3, JobTwo4]
    //     },
    //     {
    //         images: [JobThree1, JobThree2, JobThree3, JobThree4, JobThree5]
    //     },
    //     {
    //         images: [JobFour2, JobFour3]
    //     },
    //     {
    //         images: [JobFive1, JobFive2, JobFive3, JobFive4]
    //     },
    //     {
    //         images: [JobSix1, JobSix2, JobSix3, JobSix4, JobSix5, JobSix6, JobSix7, JobSix8, JobSix9, JobSix10, JobSix11, JobSix12, JobSix13, JobSix14, JobSix15]
    //     },
    //     {
    //         images: [JobSeven1, JobSeven2, JobSeven3, JobSeven4, JobSeven5, JobSeven6, JobSeven7, JobSeven8, JobSeven9, JobSeven10]
    //     }
    // ];

    const galleryList = [
        JobOne1,
        JobOne2,
        JobOne3,
        JobOne4,
        JobTwo1,
        JobTwo2,
        JobTwo3,
        JobTwo4,
        JobThree1,
        JobThree2,
        JobThree3,
        JobThree4,
        JobThree5,
        JobFour2,
        JobFour3,
        JobFive1,
        JobFive2,
        JobFive3,
        JobFive4,
        // JobSix1,
        // JobSix2,
        // JobSix3,
        // JobSix4,
        // JobSix5,
        // JobSix6,
        // JobSix7,
        // JobSix8,
        // JobSix9,
        // JobSix10,
        // JobSix11,
        // JobSix12,
        // JobSix13,
        // JobSix14,
        // JobSix15,
        JobSeven1,
        JobSeven2,
        JobSeven3,
        JobSeven4,
        JobSeven5,
        JobSeven6,
        JobSeven7,
        JobSeven8,
        JobSeven9,
        JobSeven10
    ];
    
    const galleryObject = galleryList.map((image, i) => ({
        id: i,
        image: image
    }));

    return galleryObject;
}