import React from "react";
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';
import { MdFace } from 'react-icons/md';
import '../App.scss';

export function TestimonialCard({
    itemId,
    area,
    quote,
    name
  }) {
  
    return (
      <div
        tabIndex={0}
      >
        <div className="testimonial-card" key={itemId}>
          <MdFace className="face"></MdFace>

          <Typography style={{margin: '1em', color: 'black'}} variant="body1">{quote}</Typography>
          <cite>
          <Typography variant="body1">- {name}</Typography>
          </cite>
          <cite>
          <Typography variant="body1">- {area}</Typography>
          </cite>
          <Rating name="read-only" value={5} readOnly />
        </div>
      </div>
    );
  }