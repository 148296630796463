export function Testimonials() {
    const testimonialsList = [
        {
          quote: "Highly recommend this company in every way. We had a large tree to take down, so quite a big job. From start to finish the guys were professional, friendly, hardworking and tidy. So happy with the result, thank you so much!",
          name: "Caryn",
          area: "Totton"
        },
        {
          quote: "Brilliant job! We had a TPO on our beech tree and Dan dealt with all relevant paper work and communication with council whilst keeping us informed. The tree looks great after its reduction and we will continue to use mardys tree care in the future!",
          name: "Victoria",
          area: "Ringwood"
        },
        {
          quote: "Used mardys for a hedge removal, great lads, good price! Will use again.",
          name: "Dean",
          area: "Bournemouth"
        }
    ];
    
    const getItems = () => testimonialsList.map((tes, i) => ({
        itemId: i,
        testimonial: tes
    }));

    return getItems();
}
