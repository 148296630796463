import React, { useState } from 'react';
import ReactCardFlip from 'react-card-flip';
import Typography from '@mui/material/Typography';

export function ServiceCard({
    name,
    image,
    description
}) {
    const [isFlipped, setFlip] = useState(false);

    const handleClick = () => {
        setFlip(!isFlipped);
    }

    return (
        <ReactCardFlip isFlipped={isFlipped} flipDirection="vertical">
            <div onClick={handleClick} className="service-card">
                <img src={image} alt={name} />
                    <Typography className='font-head' gutterBottom variant="h6" component="div">
                        {name}
                    </Typography>
            </div>

            <div onClick={handleClick} className="service-card">
                <div className="scroll-box">
                    <Typography variant="body2" color="text.secondary">
                        {description}
                    </Typography>
                </div>
            </div>
        </ReactCardFlip>
    );
}